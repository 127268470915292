/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import logo from './assets/Logo_Wazzan_wash.svg';
import powered from './assets/Powered_By_GOMA.png';

import { IoMdCall } from "react-icons/io";
import { BsGlobe2 } from "react-icons/bs";
import { HiLocationMarker } from "react-icons/hi";
import { FaInstagram } from "react-icons/fa";

const WazzanWash = () => {
    const [language, setLanguage] = useState('en');
useEffect(()=>{
if(localStorage.getItem('lan') =='ar'){
    setLanguage('ar')
}
else{
    setLanguage('en')
}
},[])
    const toggleLanguage = () => {
        const newLang = language === 'en' ? 'ar' : 'en';
        setLanguage(newLang);
        localStorage.setItem('lan', newLang);
    };

    const icons = [
        { icon: IoMdCall, label: language === 'ar' ? "اتصال" : "Call", link: "tel:009665333111370" },
        { icon: BsGlobe2, label: language === 'ar' ? "موقع الويب" : "Website", link: "https://www.wazzanwash.com" },
        { icon: FaInstagram, label: language === 'ar' ? "انستجرام" : "Instagram", link: "https://www.instagram.com/wazzan_wash?igsh=MWUzYXY5eHNlanBkcg==" },
        { icon: HiLocationMarker, label: language === 'ar' ? "الموقع" : "Location", link: "https://maps.app.goo.gl/wVA5qnm8QZ33KeAx8?g_st=com.google.maps.preview.copy" },
 
     
    ]; 
        {/**   { icon: FaStar, label: language === 'ar' ? "التقييم" : "Rate Us", link: "https://search.google.com/local/writereview?placeid=ChIJk9EDuObRwxURuQ3aLch6nTs" },
 */}

    const [expanded, setExpanded] = useState(false);
    const model = useRef();
    const handleClick = () => {
        setExpanded(true);
        saveToContacts()
        
        setTimeout(() => {
            setExpanded(false);
        }, 1000);
    };


    const [open, setOpen] = useState(false);

    const toggleDropdown = () => {
        setOpen(!open);
    };

    const openPDF = () => {
        const pdfPath = pdf;
        window.open(pdfPath, '_blank');
    };

    const handleDocumentClick = (e) => {
        if (model.current && !model.current.contains(e.target)) {
            setOpen(false);
        }
    };

    const saveToContacts = () => {
        const vcard =
            `BEGIN:VCARD\nVERSION:3.0\n` +
            `FN:Ahmad Jamal Abdulkhalek\n` +
            `TEL;CELL:+966597722440\n` +
            `EMAIL;HOME:a.jamal@mmg.com.sa\n` +
            `END:VCARD`;

        const blob = new Blob([vcard], { type: "text/vcard" });
        const url = URL.createObjectURL(blob);

        const downloadLink = document.createElement("a");
        downloadLink.href = url;
        downloadLink.download = `Ahmad.vcf`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    useEffect(() => {
        document.addEventListener("click", handleDocumentClick);
        return () => {
            document.removeEventListener("click", handleDocumentClick);
        };
    }, []);

    return (
        <div className='h-screen w-full flex flex-col gap-4 items-center bg-[#000]'>
        <button onClick={toggleLanguage} className=" absolute top-4 z-40 mt-10 right-4 bg-[#5B6976] text-white px-4 py-1 rounded-xl">
                {language === 'ar' ? 'EN' : 'AR'}
            </button>
            <div className='fixed w-screen h-screen -z-10 bg-[#000]'></div>
            <div className='flex justify-center items-center bg-[#000] relative z-30 pt-10'>
                <img className='w-[70vw] h-auto' src={logo} alt="La Maisons Logo" />
            </div>

            <div className='h-[30%] relative z-50 bg-[#000] w-full my-5'>
                <h2 className='text-center mb-0 font-bold text-2xl text-[#fff]'>
                {language === 'ar' ? 'وزان ووش'  : 'Wazzan Wash'}
                </h2>
            
             

                <div className='flex flex-col justify-around items-center bg-[#000]  mt-10'>
                    <div className='flex flex-col gap-3'>
                        {icons.map((item, index) => (
                            <a
                                key={index}
                                href={item.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex items-center bg-[#fff] px-3 py-3 w-[70vw] rounded-md shadow-xl"
                            >
                            {language === 'ar' ? null :   <item.icon className="w-8 h-8 text-black" />}
                              
                              
                                  
                                    {language === 'ar' ?
                                    <h2 className="flex-1 text-center  text-black text-[20px] font-bold">
                                  
                                  {item.label}
                                </h2>
                                    :      <h2 className="flex-1 text-center text-black text-[20px] font-bold">
                                  
                                    {item.label}
                                  </h2>}
                                
                                {language === 'ar' ?   <item.icon className="w-8 h-8 text-black" /> : null}

                            </a>
                        ))}
                    </div>

     {/**              <div className='flex items-center justify-center mt-2' ref={model}>
                        <div className='w-[100%] relative'>
                        <div className='fixed bottom-12 right-4'>
            <button
                onClick={handleClick}
                className={`flex items-center bg-[#0A253D] text-white rounded-full shadow-md transition-all duration-300 ease-in-out
                    ${expanded ? 'pl-4 pr-6 py-2' : 'p-3'}
                `}
            >
            
                <IoPersonAddOutline size={24} className="transition-transform duration-300" />
                {expanded ?  <span
                    className={`ml-3 transition-opacity duration-300 ease-in-out
                        ${expanded ? 'opacity-100' : 'opacity-0 invisible'}
                    `}
                >
                                        {language === 'ar' ? 'إضافة إلى جهات الاتصال' : 'Add To Contacts'}
                </span>:''}
            </button> 
        </div>
                        </div>
                    </div>
*/}  
                    <div className='mt-14 mb-2 '>
                        <a href="https://social.gomaksa.com/omar" className="cursor-pointer" target="_blank" rel="noopener noreferrer">
                            <img src={powered} alt="Powered By Goma" className='w-32 h-auto mx-auto' />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WazzanWash;
